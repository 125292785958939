import { PatientMatch } from "./patientTypes"

export enum ToastTypes {
  SET_OPEN_TOAST = "@@TOAST/SET_OPEN_TOAST",
  REMOVE_OPEN_TOAST = "@@TOAST/REMOVE_OPEN_TOAST",
}

export enum ToastType {
  matches = "patients matches",
  linkedPatients = "link patients",
  copiedToken = "Copied token",
  copiedSessionId = "Copied session id",
  connectIntegration = "Connect integration",
  connectIntegrationError = "Connect integration error",
  invalidUuid = "invalid UUID",
}

export type ToastPopUp = Readonly<{
  type: ToastType
  message: string | React.ReactElement
  patientMatch?: PatientMatch
  notificationType: "error" | "success" | "information" | "warning"
}>
