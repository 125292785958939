import { call, put, select, takeLatest } from "redux-saga/effects"

import localStorage from "library/utilities/localStorage"
import {
  ActionLogin,
  ActionResetPasswordFirstTime,
  loginActionSuccess,
  loginAction,
  loginActionError,
  newPasswordErrorAction,
  oldPasswordErrorAction,
  isLoadingResetPasswordAction,
  isSuccessResetPassword,
  setMustResetPassword,
  setUserInfo,
  setServerError,
  setHandlerHash,
  CoachMarkRecord,
  setGeneratedToken,
  setHandlerName,
  getUserName as getUserNameAction,
} from "library/common/actions/user"
import { UserTypes } from "library/common/types/userTypes"
import { uploadActionNewImage } from "library/common/actions/upload"
import {
  clearSsoProvider,
  saveOauthResponse,
  setBearerToken,
  setSsoProvider,
} from "library/utilities/token"
import {
  requestLogin,
  requestGetUserName,
  requestSetKnownHandlers,
  requestSetWhatsNew,
  requestSetLastCoachMarks,
  requestGenerateToken,
  requestSetAlfaDocsApiKey,
} from "library/services/userApi"
import {
  requestResetPassword,
  requestOnboarding,
} from "library/services/resetPasswordApi"
import { history } from "core/store/configureStore"
import { sha256 } from "library/utilities/hash"
import {
  getHandlerHash,
  getHandlerName,
  getKnownHandlers,
  getLastCoachMarks,
  getContextQueryParams,
  getUsername,
} from "../selectors/user"
import {
  SHOW_PENDO_INTEGRATION,
  SHOW_SSO,
  WHATS_NEW_VERSION,
} from "library/utilities/constants"
import axios from "axios"
import { openModal } from "../actions/modal"
import { Modals } from "../reducers/modalsReducer"
import { getIsIntegrated } from "../selectors/routes"
import { AuthResponse } from "../types/authTypes"
import { ContextQuery } from "../types/userTypes"
import {
  setImageIDBreadcrumb,
  setPatientFileBreadcrumb,
} from "../actions/breadcrumbs"
import * as userActions from "library/common/actions/user"
import { setOpenToast } from "../actions/toast"
import { ToastType } from "../types/toast"
import i18next from "i18next"
import { ResultStatus } from "../types/dataStructureTypes"

interface ILoginUser {
  payload: ActionLogin
  type: string
}

function* loginUser(userData: ILoginUser) {
  try {
    const authResponse: AuthResponse = yield call(
      requestLogin,
      userData.payload
    )
    const username: string = yield select(getUsername)

    localStorage.setItem(
      "installer_by_default",
      !!authResponse.data.installerByDefault
    )

    if (!userData.payload.isSSO) {
      if (username !== userData.payload.username) {
        yield put(setPatientFileBreadcrumb(""))
        yield put(setImageIDBreadcrumb(""))
      }

      localStorage.setItem("username", userData.payload.username)
      clearSsoProvider()
    } else {
      setSsoProvider(userData.payload.username)
    }
    if (!authResponse.data.mustResetPassword) {
      sessionStorage.setItem("access_token", authResponse.data.token)
      saveOauthResponse(authResponse.data.oAuthResponse)
    }

    setBearerToken(authResponse.data.token)
    yield put(uploadActionNewImage())
    yield put(loginActionSuccess(userData.payload))

    if (authResponse.data.mustResetPassword && !authResponse.data.SSO) {
      const { fullName, email, SSO } = authResponse.data
      yield put(setUserInfo({ fullName, email, SSO }))

      yield put(setMustResetPassword(true))
      yield put(loginAction())
      history.push("/onboarding", { token: authResponse.data.token })
    } else {
      yield put(loginAction())
    }
  } catch (error) {
    let errorCode = 600
    if (axios.isAxiosError(error)) {
      errorCode = error.response?.status === 400 ? 400 : 500
    }
    yield put(loginActionError(errorCode))
  }
}

interface IResetPassword {
  payload: {
    oldPassword?: string
    newPassword: string
    fullName?: string
    email?: string
    telephoneNumber?: string
    isFirstTime: boolean
  }
  type: string
}

interface IResetPasswordFirstTime {
  payload: ActionResetPasswordFirstTime
  type: string
}

function* resetPasswordFirstTime(data: IResetPasswordFirstTime) {
  const { token, ...resetPasswordPayload } = data.payload
  setBearerToken(token)
  const payload = {
    ...resetPasswordPayload,
    isFirstTime: true,
  }

  // Set handler to prevent showing NewUserOnboarding modal with checkboxes again.
  yield call(submitHandlerHash)
  yield put(setHandlerName(data.payload.fullName))
  yield resetPassword({ payload, type: UserTypes.RESET_PASSWORD_ACTION })
}

function* resetPassword(password: IResetPassword) {
  const token = sessionStorage.getItem("access_token")
  if (token) setBearerToken(token)

  try {
    yield put(oldPasswordErrorAction(""))
    yield put(newPasswordErrorAction(""))
    yield put(isSuccessResetPassword(false))
    yield put(isLoadingResetPasswordAction(true))

    const { isFirstTime, ...data } = password.payload
    const passwordResponse: { data: { token: string } } = yield call(
      isFirstTime ? requestOnboarding : requestResetPassword,
      data
    )
    if (passwordResponse.data.token) {
      sessionStorage.setItem("access_token", passwordResponse.data.token)
      setBearerToken(passwordResponse.data.token)
    }
    if (isFirstTime) {
      yield put(setMustResetPassword(false))
    }
    yield put(isLoadingResetPasswordAction(false))
    yield put(isSuccessResetPassword(true))
  } catch (error) {
    yield put(isSuccessResetPassword(false))
    yield put(isLoadingResetPasswordAction(false))

    const errorData = error.response && error.response.data

    if (
      errorData &&
      errorData.newPassword &&
      typeof errorData.newPassword !== "string"
    ) {
      const commonPasswordError = errorData.newPassword.filter(
        (item: string) => item.indexOf("This password is too common.") !== -1
      )
      if (commonPasswordError.length > 0) {
        yield put(newPasswordErrorAction("app.onboarding.common_password"))
      } else if (
        errorData.newPassword[0].includes(
          "The password is too similar to the username."
        )
      ) {
        yield put(newPasswordErrorAction("app.onboarding.password_too_similar"))
      } else if (
        errorData.newPassword ===
        "newPassword must be different from previous password"
      ) {
        yield put(newPasswordErrorAction("app.onboarding.different_password"))
      }
    } else if (
      errorData &&
      errorData.newPassword &&
      errorData.newPassword ===
        "newPassword must be different from previous password"
    ) {
      yield put(newPasswordErrorAction("app.onboarding.different_password"))
    }
    if (errorData && errorData.oldPassword) {
      const isOldErrorPassword =
        errorData.oldPassword.indexOf("Wrong old password") !== -1
      if (isOldErrorPassword) {
        yield put(oldPasswordErrorAction("app.onboarding.old_password_error"))
      }
    }

    if (
      axios.isAxiosError(error) &&
      error.response &&
      error.response.status &&
      (!errorData || (!errorData.newPassword && !errorData.oldPassword))
    ) {
      yield put(setServerError("unavailable"))
    } else if (
      !navigator.onLine ||
      (axios.isAxiosError(error) && !error.response)
    ) {
      yield put(setServerError("disconnect"))
    }
  }
}

function* getUserName() {
  try {
    const params: ContextQuery = yield select(getContextQueryParams)
    const { data } = yield call(requestGetUserName, params)
    const {
      fullName,
      email,
      SSO,
      knownHandlers,
      cariesPro,
      bonelossPro,
      boneLossLite,
      uploadsRemaining,
      whatsNew,
      lastCoachMarks,
      theme,
      calculus,
      nervus,
      impacted,
      licence,
      licenceExpire,
      showDrawingMode,
      boneLossOnly,
      mustResetPassword,
      modalities,
      numberingSystem,
      toothBasedPeri,
      role,
      installerByDefault,
      showAlfaDocs,
      hidePatientId,
      alfaDocsApiKey,
      username,
    } = data
    yield put(
      setUserInfo({
        fullName,
        email,
        SSO,
        knownHandlers,
        cariesPro,
        bonelossPro,
        boneLossLite,
        uploadsRemaining,
        whatsNew,
        lastCoachMarks,
        theme,
        calculus,
        nervus,
        impacted,
        licence,
        licenceExpire,
        showDrawingMode,
        boneLossOnly,
        mustResetPassword,
        modalities,
        numberingSystem,
        toothBasedPeri,
        role,
        installerByDefault,
        showAlfaDocs,
        hidePatientId,
        alfaDocsApiKey,
        username,
      })
    )

    localStorage.setItem("installer_by_default", !!data.installerByDefault)
    const handlerName: string = yield select(getHandlerName)
    const handlerHash: string = yield select(getHandlerHash)
    const isIntegrated: boolean = yield select(getIsIntegrated)
    const isReportPage = history.location.pathname.includes("report")

    /*
      Never show onboarding modal if user has to reset their password as the
      onboarding checkboxes are already shown in the reset password form
    */
    if (
      !mustResetPassword &&
      ((SHOW_SSO && knownHandlers?.length === 0) ||
        (isIntegrated &&
          !!handlerName &&
          !!handlerHash &&
          !knownHandlers.includes(handlerHash) &&
          !isReportPage))
    ) {
      yield put(openModal(Modals.NEW_USER_ONBOARDING_MODAL))
    }

    if (SHOW_PENDO_INTEGRATION && (window as any).pendo) {
      // This function creates visitors and accounts in Pendo
      ;(window as any).pendo.initialize({
        visitor: {
          id: username, // Required if user is logged in
        },

        account: {
          id: username, // Highly recommended, required if using Pendo Feedback
          environment: i18next.t(
            "app.modal_product_information.release_version"
          ),
        },
      })
    }
  } catch (error) {
    console.error(error)
  }
}

function* submitHandlerHash() {
  try {
    const hash: string = yield select(getHandlerHash)
    const handlers: string[] = yield select(getKnownHandlers) ?? []
    const { data } = yield call(requestSetKnownHandlers, {
      knownHandlers: handlers.concat([hash]),
    })
    const { fullName, email, SSO, knownHandlers } = data
    yield put(setUserInfo({ fullName, email, SSO, knownHandlers }))
  } catch (error) {
    console.log(error)
  }
}

function* computeHandlerHash({ payload }: { payload: string; type: string }) {
  const handlerHash: string = yield call(sha256, payload)
  const currentHandlerHash: string = yield select(getHandlerHash)
  if (handlerHash !== currentHandlerHash) {
    yield put(setHandlerHash(handlerHash))
  }
}

export function* setWhatsNew() {
  try {
    yield call(requestSetWhatsNew, { whatsNew: WHATS_NEW_VERSION })
  } catch (error) {
    console.log("could not set whatsNew version", error)
  }
}

export function* generateTokenSaga() {
  try {
    const params: ContextQuery = yield select(getContextQueryParams)
    const { data } = yield call(requestGenerateToken, params)
    yield put(
      setGeneratedToken({
        token: data.key,
        sessionId: data.data?.Data?.SessionId,
      })
    )
  } catch (error) {
    console.error("could not generate token", error)
  }
}

export function* setLastCoachMarks() {
  const lastCoachMarks: CoachMarkRecord = yield select(getLastCoachMarks) ?? {}
  try {
    yield call(requestSetLastCoachMarks, {
      lastCoachMarks,
    })
  } catch (error) {
    console.error("could not set lastCoachMark version", error)
  }
}

export function* setAlfaDocsApiKeySaga({
  payload: apiKey,
}: ReturnType<typeof userActions.setAlfaDocsApiKey>) {
  yield put(userActions.setUserIntegrationResultStatus(ResultStatus.loading))
  try {
    yield call(requestSetAlfaDocsApiKey, apiKey)
    yield put(
      setOpenToast({
        type: ToastType.connectIntegration,
        notificationType: "success",
        message: i18next.t(
          `app.toast.${apiKey.length === 0 ? "successfully_disconnected" : "successfully_connected"}`
        ),
      })
    )

    yield put(getUserNameAction())
    yield put(userActions.setUserIntegrationResultStatus(ResultStatus.none))
  } catch (error) {
    yield put(
      setOpenToast({
        type: ToastType.connectIntegrationError,
        notificationType: "error",
        message: i18next.t("app.toast.error"),
      })
    )
    yield put(userActions.setUserIntegrationResultStatus(ResultStatus.error))
  }
}

export default function* watchLogin() {
  yield takeLatest(UserTypes.LOGIN_ACTION_WITH_DATA, loginUser)
  yield takeLatest(UserTypes.RESET_PASSWORD_ACTION, resetPassword)
  yield takeLatest(
    UserTypes.RESET_PASSWORD_FIRST_TIME_ACTION,
    resetPasswordFirstTime
  )
  yield takeLatest(UserTypes.GET_USER_NAME, getUserName)
  yield takeLatest(UserTypes.SET_HANDLER_NAME, computeHandlerHash)
  yield takeLatest(UserTypes.SUBMIT_HANDLER_HASH, submitHandlerHash)
  yield takeLatest(UserTypes.SET_WHATS_NEW, setWhatsNew)
  yield takeLatest(UserTypes.SET_LAST_COACH_MARKS, setLastCoachMarks)
  yield takeLatest(UserTypes.GENERATE_TOKEN, generateTokenSaga)
  yield takeLatest(UserTypes.SET_ALFA_DOCS_API_KEY, setAlfaDocsApiKeySaga)
}
